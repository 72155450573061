<template>
  <ul class="gridPro clearfix" v-if="productsWithIndex && productsWithIndex.length>0">
    <li v-for="(data, index) in productsWithIndex" :key="index" :class="'gridLi li' + data.product.id">
      <div class="proGridBox">
        <p class="gridImgA">
          <span v-if="data.images?.length > 1" class="grid_z gridPrev" @click="changeImage(-1,data)"></span>
          <span v-if="data.images?.length > 1" class="grid_y gridNext" @click="changeImage(1,data)"></span>
          <span v-if="data.images?.length > 1" class="grid_k gridIndex">
            {{ data.index + 1 }}/{{ data.images.length }}
          </span>

          <span class="GoCart AddToCartBtn" v-addToCart.stop="{ id: data.product.id, qty: 1 }">Add</span>
          <span class="buyNow buyNowBtn" v-buyNow.stop="{ id: data.product.id, qty: 1 }">Buy</span>
          <span class="Collect addToWishListBtn" :key="data.product.id" v-addToWishList.stop="data.product.id">Save</span>

          <span v-if="data.product.salePrice <= 0" class="girdFree"></span>
          <span v-if="data.product.isPoolProduct" class="hot"></span>
          <a :title="data.product.name" :href="getProductDetailUrl(data.product.id, data.product.name)" rel="nofollow" class="imgSize240px">
            <img width="245"
                 height="245"
                 :data-img="getPhotoUrl(data.images[data.index],220)"
                 v-lazy-load-img="{ photoName: data.images[data.index], size: 220, defaultImageUrl: '/imgs/load_1_1.gif' }"
                 :alt="data.product.name" />
          </a>
        </p>
        <div class="proGridHover hidden-xs clearfix" v-if="data.collectiveProducts">
          <div v-if="data.collectiveProducts.length > 0" :class="['proListShow', 'proGridShow', { 'hasScroll': data.collectiveProducts.length > 4 }, 'small-img-container-' + index]">
            <span class="page prev" v-if="data.collectiveProducts.length > 4" @click="scrollImages('prev', index)"></span>
            <div class="setListWrap">
              <p class="setList" :style="{ width: (50 * data.collectiveProducts.length - 4) + 'px' }">
                <a v-for="(pc, pcIndex) in data.collectiveProducts" :key="pcIndex" :class="{ 'on': data.product.id === pc.productId }" @click="changeCollective(data.product.id,pc.productId)" >
                  <img width="38" height="38" alt="" :src="getPhotoUrl(pc.mainImageName, 60)" />
                  <span class="chooseIcon" v-if="data.product.id === pc.productId"></span>
                </a>
              </p>
            </div>
            <span class="page next" v-if="data.collectiveProducts.length > 4" @click="scrollImages('next', index)"></span>
          </div>
        </div>
        <p class="name">
          <span v-if="data.product.isPromotion" class="discountBg">{{data.product.discount}}% OFF</span>
          <a :title="data.product.name" :href="getProductDetailUrl(data.product.id, data.product.name)">
            {{ getLimitStringByWord(data.product.name, 50).decodeHtml() }}
          </a>
        </p>
        <div class="proGridHover clearfix hidden-sm">
          <p class="freeIcon clearfix">
            <span v-if="data.product.isNickelFreeProduct || data.product.isRoshStandardProduct">
              <a v-if="data.product.isNickelFreeProduct" title="Nickel Free" class="nickelFree"></a>
              <a v-if="data.product.isRoshStandardProduct" title="Rosh Standard" class="roHsCertified"></a>
            </span>
            <span v-if="data.product.isCloseout" class="listLimited" title="Limited Quantity"></span>
            <span v-if="data.product.isMixProduct && data.product.mixProductType === 0" class="listRandomly" title="Randomly Mixed"></span>
            <span v-if="data.product.isMixProduct && data.product.mixProductType !== 0" class="listAssortment" title="Assortment Mixed"></span>
          </p>
          <p class="listWt">
            <span>Shipping Weight:</span>
            {{ getWeightString(data.product.shippingWeight, false) }} g
          </p>
          <div class="listPackage clearfix">
            <span class="lSpan fl">Package Size:</span>
            {{ getUnitString(data.product.unitQuantity, data.product.unit) }}
          </div>
        </div>

        <template v-if="data.product.salePrice2 && data.product.salePrice2 > 0">
          <ul class="gridInf gridStepArea">
            <li>
              <span class="red">
                {{ data.product.finalSalePrice.money() }} ~ {{ data.product.finalSalePrice3.money() }}
              </span>
            </li>
          </ul>
          <ul class="gridInf gridStepInf clearfix hidden-sm">
            <li>
              <span class="gray">1-{{ data.product.step2 - 1 }}:</span>
              <span class="red">{{ data.product.finalSalePrice.money() }}</span>
            </li>
            <li>
              <span class="gray">{{ getStepRange(data.product.step2, data.product.step3) }}:</span>
              <span class="red">{{ data.product.finalSalePrice2.money() }}</span>
            </li>
            <template v-if="data.product.salePrice3 && data.product.salePrice3 > 0">
              <li>
                <span class="gray">{{ data.product.step3 }}+:</span>
                <span class="red">{{ data.product.finalSalePrice3.money() }}</span>
              </li>
            </template>
          </ul>
        </template>
        <template v-else>
          <ul class="gridInf">
            <template v-if="data.product.salePrice <= 0">
              <li class="gridFreeIcon"></li>
            </template>
            <template v-else>
              <li>
                <span class="red">{{ data.product.finalSalePrice.money() }}</span>&nbsp;/&nbsp;{{ getUnitString(data.product.unitQuantity, data.product.unit) }}
              </li>
            </template>
            <li class="priceBox clearfix" v-if="data.product.isPromotion">
              <p class="oldPrice">{{ data.product.salePrice.money() }}&nbsp;/&nbsp; {{ getUnitString(data.product.unitQuantity, data.product.unit) }}</p>
            </li>
          </ul>
        </template>
        <div class="clearfix">
          <p class="stock leadTime">
            <a v-if="data.product.stockQty > 0" rel="nofollow" target="_blank" href="/HelpCenter/HelpDetail?articleId=126&amp;articleIndex=11"
               class="ReadyTimethree"><span>Ready Time:</span>{{ Const.instockReadyDays }} Days</a>
            <a v-else rel="nofollow" :class="data.product.readylevel == '8' ? 'readyTimeEight' : data.product.readylevel == '15' ? 'readyTimefifteen' : ''"
               target="_blank" href="/HelpCenter/HelpDetail?articleId=126&amp;articleIndex=11"><span>Ready Time:</span>
              {{ data.product.readylevel }} Days
            </a>
          </p>
        </div>
        <template v-if="data.collectiveProducts && data.collectiveProducts.length > 1">
          <div class="seeSize hidden-sm hidden-md">
            <a :href="getProductDetailUrl(data.product.id, data.product.name)">See Size & Color Options</a>
          </div>
        </template>
      </div>
    </li>
  </ul>
</template>
<script src="./ProductItem.ts"></script>
<style src="./productItem.less" lagn="less" scoped></style>